<template>
    <div class="fixed inset-x-0 bottom-0 z-10 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <transition
            enter-active-class="duration-300 ease-out"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-200 ease-in"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div class="fixed inset-0 transition-opacity">
                <div class="absolute inset-0 opacity-75 bg-neutral-800"></div>
            </div>
        </transition>

        <div class="overflow-hidden transition-all transform bg-white rounded-lg shadow-xl sm:max-w-md sm:w-full">
            <div class="px-4 py-4 text-center bg-white sm:p-7 sm:pb-7">
                <div class="flex justify-center w-full sm:mt-0 sm:w-auto">
                    <SelfBuildingSquareSpinner :size="32" color="#9e4526" />
                </div>
                <h1 class="mt-4 font-medium text-center text-md text-neutral-600">
                    {{ message }}
                </h1>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { SelfBuildingSquareSpinner } from 'epic-spinners';

export default defineComponent({
    name: 'LoadingModal',
    components: {
        SelfBuildingSquareSpinner,
    },
    props: {
        message: {
            type: String,
            required: true,
        },
    },
});
</script>
