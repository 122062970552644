<template>
    <div class="p-4 border-yellow-400 bg-yellow-50">
        <div class="flex items-center">
            <div class="flex-shrink-0">
                <ExclamationIcon class="w-6 h-6 text-yellow-500" aria-hidden="true" />
            </div>
            <div class="ml-3">
                <div class="font-medium text-neutral-800">
                    <span>You have no linked Ethereum wallet</span>
                </div>
                <div class="text-neutral-700">
                    <div>
                        You can
                        <a
                            href="javascript:void(0)"
                            @click="$emit('showImportWalletModal', 'Create')"
                            class="underline hover:text-primary-700 text-primary-600"
                            >create</a
                        >
                        or
                        <a
                            href="javascript:void(0)"
                            @click="$emit('showImportWalletModal', 'Import')"
                            class="underline hover:text-primary-700 text-primary-600"
                            >import</a
                        >
                        an existing wallet.
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { ExclamationIcon } from '@heroicons/vue/solid';
import store from '@/store';

export default defineComponent({
    name: 'WalletAlert',
    components: {
        ExclamationIcon,
    },
    setup() {
        const user = ref(store.state.user);

        return { user };
    },
});
</script>
