<template>
    <form-modal
        :title="title"
        @cancel="hideModal"
        @save="save"
        :loading="loading"
        :saveButtonText="type"
        :validationSchema="validationSchema"
    >
        <div class="space-y-4" v-if="!loading">
            <p class="text-neutral-600">
                {{ description }}
            </p>
            <div class="space-y-4">
                <div v-if="type === 'Import'">
                    <InputField
                        name="privateKey"
                        label="Private Key"
                        v-model="privateKey"
                        type="password"
                        placeholder="Enter private key"
                        :disabled="loading"
                        @input="privateKeyError = null"
                    />
                    <span v-if="privateKeyError" class="text-sm text-red-600">{{ privateKeyError }}</span>
                </div>
                <InputField
                    name="walletPassword"
                    label="Wallet Password"
                    v-model="password"
                    type="password"
                    placeholder="Enter wallet password"
                    :disabled="loading"
                    @keyup.enter="save"
                    @input="walletPasswordError = null"
                />
                <span v-if="walletPasswordError" class="text-sm text-red-600">{{ walletPasswordError }}</span>
            </div>
        </div>
        <div class="mt-2 space-y-2" v-else>
            <div class="flex justify-center">
                <tw-progress-bar color="bg-secondary-500" :percentage="progress">
                    <span class="flex justify-end w-full pr-2 text-xs text-white">{{ progress }}%</span>
                </tw-progress-bar>
            </div>
            <p class="text-center text-neutral-600">{{ loadingMessage }}</p>
        </div>
    </form-modal>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { defineRule } from 'vee-validate';
import { required, min } from '@vee-validate/rules';
import store from '@/store';
import { FormModal, TwProgressBar, InputField } from '@/components';
import { Wallet, ethers } from 'ethers';
import { UsersAPI } from '@/api';
import { useToast } from 'vue-toastification';

defineRule('required', required);
defineRule('min', min);
defineRule('strong_password', value => {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
    if (strongRegex.test(value)) {
        return '';
    }
    return `The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`;
});

export default defineComponent({
    name: 'WalletModal',
    components: { FormModal, TwProgressBar, InputField },
    props: {
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        loadingMessage: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: 'Import',
        },
        callback: {
            type: Function,
            default: () => null,
        },
    },
    setup(props, { emit }) {
        const toast = useToast();
        const form = ref(null);
        const password = ref('');
        const privateKey = ref('');
        const progress = ref(0);
        const loading = ref(false);
        const privateKeyError = ref(null);
        const walletPasswordError = ref(null);

        const ethwallet = computed(() => store.state.wallet);

        const validationSchema = {
            privateKey: props.type === 'Import' ? 'required' : null,
            walletPassword: props.type === 'Unlock' ? 'required' : 'required|min:8|strong_password',
        };

        const walletProgress = progressValue => {
            progress.value = Math.round(progressValue * 100);
        };

        const hideModal = () => {
            emit('cancel');
        };

        const createWallet = async () => {
            try {
                loading.value = true;

                const encWallet = await store.dispatch('createWallet', {
                    password: password.value,
                    callback: walletProgress,
                });

                password.value = '';
                walletPasswordError.value = null;

                UsersAPI.addEthWallet({
                    ethaddress: JSON.parse(encWallet).address,
                    ethwallet: encWallet,
                })
                    .then(() => {
                        store.commit('SET_WALLET', encWallet);
                        toast.success('Ethereum wallet imported!');
                    })
                    .catch(() => {
                        toast.error('Failed to import ethereum wallet');
                    });

                hideModal();
            } catch (e) {
                walletPasswordError.value = 'Wallet creation failed';
            }
            loading.value = false;
        };

        const importWallet = async () => {
            try {
                loading.value = true;
                const encWallet = await store.dispatch('importWallet', {
                    privateKey: privateKey.value,
                    password: password.value,
                    callback: walletProgress,
                });
                password.value = '';
                privateKey.value = '';
                privateKeyError.value = null;
                UsersAPI.addEthWallet({
                    ethaddress: JSON.parse(encWallet).address,
                    ethwallet: encWallet,
                })
                    .then(() => {
                        store.commit('SET_WALLET', encWallet);
                        toast.success('Ethereum wallet imported!');
                    })
                    .catch(() => {
                        toast.error('Failed to import ethereum wallet');
                    });
                hideModal();
            } catch (e) {
                privateKeyError.value = 'Invalid private key';
            }
            loading.value = false;
        };

        const unlockWallet = async () => {
            loading.value = true;
            walletPasswordError.value = null;
            try {
                const provider = new ethers.providers.JsonRpcProvider(process.env.VUE_APP_ETH_NODE);
                let wallet = await Wallet.fromEncryptedJson(ethwallet.value, password.value, walletProgress);
                wallet = wallet.connect(provider);
                hideModal();
                await props.callback(wallet);
            } catch {
                walletPasswordError.value = 'Invalid password';
            }
            loading.value = false;
        };

        const save = async () => {
            if (props.type === 'Import') {
                await importWallet();
            } else if (props.type === 'Unlock') {
                await unlockWallet();
            } else if (props.type === 'Create') {
                await createWallet();
            }
        };

        return {
            validationSchema,
            password,
            loading,
            progress,
            privateKey,
            hideModal,
            form,
            privateKeyError,
            walletPasswordError,
            save,
        };
    },
});
</script>
