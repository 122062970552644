<template>
    <router-link :to="`/contracts/${contract.uuid}`">
        <div class="flex flex-col w-full px-2 py-4 bg-white hover:bg-primary-50">
            <div class="flex flex-row items-center justify-between flex-initial">
                <div class="flex flex-col w-full px-2 space-y-2">
                    <div class="flex flex-row items-center space-x-2">
                        <button type="button" class="text-lg font-semibold text-primary-600 focus:outline-none">
                            Contract #{{ contract.id }}
                        </button>
                        <div class="flex flex-row">
                            <div class="flex items-center">
                                <span
                                    class="px-2 py-1 text-xs tracking-wider capitalize rounded-full"
                                    :class="statusClasses(contract.status)"
                                    >{{ contract.status }}</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center space-x-4">
                        <div class="flex items-center">
                            <CalendarIcon class="w-4 h-4 text-neutral-500" />
                            <span class="ml-2 text-sm text-neutral-600"
                                >Created at {{ dayjs(contract.createdAt).format('MMM D, YYYY') }}</span
                            >
                        </div>
                        <div class="flex items-center">
                            <CollectionIcon
                                class="w-4 h-4 text-neutral-500"
                                v-tooltip="'Number of assets included in the contract'"
                            />
                            <span class="ml-1 text-sm text-neutral-600 "
                                ><span class="font-bold">{{ contract.assets.length }}</span> device(s)</span
                            >
                        </div>
                        <div class="flex items-center">
                            <LightningBoltIcon class="w-4 h-4 text-neutral-500" />
                            <span class="ml-1 text-sm text-neutral-600 "
                                ><span class="font-bold">{{ contract.flexibilityCapacity }}</span> KW flex capacity
                            </span>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col items-end ">
                    <div class="flex items-center px-2">
                        <router-link
                            :to="`/contracts/${contract.uuid}/performance-overview`"
                            v-if="
                                contract.status === ContractStatus.active || contract.status === ContractStatus.closed
                            "
                        >
                            <ChartSquareBarIcon
                                v-tooltip="'Performance'"
                                class="w-5 h-5 text-neutral-400 hover:text-primary-500"
                            />
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import { defineComponent } from 'vue';
import { CalendarIcon, ChartSquareBarIcon, CollectionIcon, LightningBoltIcon } from '@heroicons/vue/outline';
import { useContracts } from '@/composables';
import dayjs from 'dayjs';
import { ContractStatus } from '../constants';

export default defineComponent({
    name: 'ContractCard',
    components: { CalendarIcon, ChartSquareBarIcon, CollectionIcon, LightningBoltIcon },
    props: {
        contract: {
            type: Object,
            required: true,
        },
    },
    setup() {
        const { statusClasses } = useContracts();

        return {
            dayjs,
            statusClasses,
            ContractStatus,
        };
    },
});
</script>
