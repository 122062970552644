<template>
    <div class="px-8 py-6 bg-white border rounded-md border-neutral-200">
        <div class="mb-4 text-sm font-semibold tracking-wide uppercase text-neutral-500">
            Flexibility Details
        </div>
        <div class="flex items-center justify-between mb-2 font-medium tracking-wide text-neutral-700">
            <div class="w-1/5">Flexibility Name</div>
            <div class="w-1/5">Device Type</div>
            <div class="w-1/5">Flexibility Capacity</div>
            <div class="w-1/5">Nominal Power</div>
        </div>
        <div
            class="flex items-center justify-between py-1 mb-1 text-sm text-neutral-500"
            v-for="asset in assets"
            :key="asset.id"
        >
            <div class="w-1/5 truncate">{{ asset.title }}</div>
            <div class="w-1/5">{{ asset.type }}</div>
            <div class="w-1/5">{{ asset.flexibilityCapacity }} KW</div>
            <div class="w-1/5">{{ asset.nominalPower }} KW</div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ContractAssets',
    props: {
        assets: {
            type: Array,
            default: () => [],
        },
    },
});
</script>
